<template lang="">
  <div>
    <base-header class="pb-7 pt-md-6 bg-gradient-dark"></base-header>
    <div class="order-xl-1">
      <div class="card">
        <div class="card-header">
          <b-row align-v="center" slot="header" >
            <b-col cols="8">
              <h2 class="mb-0"> <i class="fas fa-pen"></i> กำหนดสิทธิ์การใช้งาน </h2>
            </b-col>
          </b-row>
        </div>

        <div class="card-body bg-white">
          <validation-observer v-slot="{handleSubmit}" ref="formValidator">
            <b-form @submit.prevent="handleSubmit(editHandler)">
              <table class="table table-bordered table-sm">
                <thead>
                    <tr class="text-center">
                      <th style="width: 50px;"></th>
                      <th>Application</th>
                      <th>View</th>
                      <th>Add</th>
                      <th>Edit</th>
                      <th>Remove</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="app in permissions" :key="app.app_name">
                      <td class="text-center">
                        <b-form-checkbox @change="selectAll(app)" v-model="app.selectAll" :check="checkSelectAll(app)"/>
                      </td>
                      <td>{{app.app_name}}</td>
                      <td class="text-center">
                        <b-form-checkbox v-model="app.action.flag_view"/>
                      </td>
                      <td class="text-center">
                        <b-form-checkbox v-model="app.action.flag_insert"/>
                      </td>
                      <td class="text-center">
                        <b-form-checkbox v-model="app.action.flag_update"/>
                      </td>
                      <td class="text-center">
                        <b-form-checkbox v-model="app.action.flag_delete"/>
                      </td>
                    </tr>
                </tbody>
              </table>
              <hr class="my-4">
              <div class="float-right d-flex">
                <span><router-link :to="'/group'" class="nav-link text-underline"> กลับ</router-link></span>
                <!-- <b-button variant="secondary" @click="onBackHandler()"><i class="fa fa-arrow-left"></i> กลับ</b-button> -->
                <b-button variant="primary" type="submit" class="style_btn" v-if="permission.actionData.flag_update"><i class="fa fa-check"></i> บันทึก</b-button>
              </div>
            </b-form>
          </validation-observer>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import formGroup from './form.group';
import * as scripts from '../../../util/scripts';
export default {
  name: 'group-view-page-group-permission',
  data () {
    return {
      permission:{
        appSlug: 'group',
        actionData: {}
      },
      permissions: [],
      funcs: scripts.funcs,
      id: '',
    }
  },
  methods: {
    onOutsideSubmit(){
      this.$refs.editGroupForm.click();
    },
    async editHandler () {
      const result = await this.HttpServices.postData(`/ab_groups/permission/${this.id}`, {permissions: JSON.stringify(this.permissions)});
      if(result&&result.status.code=="200"){
        this.AlertUtils.alertCallback('success', `บันทึกสำเร็จ`, ()=>{
          this.$router.push('/group');
        });
      }else{
        this.AlertUtils.alert('warning', result.status.message);
      }อ้อ
    },
    onBackHandler(){
      this.$router.push("/group");
    },
    selectAll(item){
      item.action.flag_view = item.selectAll;
      item.action.flag_insert = item.selectAll;
      item.action.flag_update = item.selectAll;
      item.action.flag_delete = item.selectAll;
    },
    checkSelectAll(item){
      if(item.action.flag_view&&item.action.flag_insert&&item.action.flag_update&&item.action.flag_delete){
        item.selectAll = true;
      }else{
        item.selectAll = false;
      }
    },
    async getGroup () {
      const result = await this.HttpServices.getData(`/ab_groups/permission/${this.id}`);
      if(result&&result.status.code=="200"){
        const data = result.data;
        this.permissions = data;
        for(const v of this.permissions){
          if(v.action.flag_view&&v.action.flag_insert&&v.action.flag_update&&v.action.flag_delete){
            v.selectAll = true;
          }else{
            v.selectAll = false;
          }
        }
      }else{
        this.AlertUtils.alertCallback('warning', `ไม่พบข้อมูล Permission`, ()=>{
          this.$router.push('/group');
        });
      }
    }
  },
  components: {
    formGroup
  },
  async mounted () {
    await this.PermissionServices.viewPermission(this.permission.appSlug, this.$router);
    this.permission.actionData = await this.PermissionServices.getPermission(this.permission.appSlug);
    this.id = await this.SessionStorageUtils.getSession("page-group");
    await this.getGroup();
  }
}
</script>
